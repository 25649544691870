import React from "react";
import { pagesRoutes } from "@utils/variables";
import { useTranslation } from "react-i18next";
import { down } from "styled-breakpoints";
import styled from "styled-components";
import { motion } from "framer-motion";
import tw from "twin.macro";

import LinkPrimary from "@components/links/primary";

const Wrapper = tw.div`relative ml-40 mr-10 md:w-1/2 md:mx-0`;

const Header = styled(motion.div)`
  ${tw`
    mb-10 text-xl leading-33 -tracking-016
    md:mt-140 md:mb-60 md:ml-10 md:text-2xl
    lg:relative lg:top-280 lg:mt-130 lg:ml-0 lg:mb-30 lg:text-4xl lg:leading-60 lg:-tracking-028
    lg:opacity-0
  `}
  
  ${down('md')} {
    transform: none !important;
  }
`;

const TextShadow = tw(motion.span)`
  absolute -top-30 -left-40 text-180 text-portfolioText opacity-10 -z-1 -tracking-021
  md:top-80 md:left-0 md:-tracking-187
  lg:top-30 lg:-left-70 lg:text-250 lg:opacity-0
`;

const LinkWrapper = tw(motion.div)`mt-70 lg:opacity-0`;

const NotFoundPageInfo = ({ width }: { width: number }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header
        animate={ width >= 1024 && { y: -280, opacity: 1 }}
        transition={{
          opacity: { duration: .542, delay: .242, ease: 'linear' },
          y: { duration: .875, delay: .542, ease: [.84, 0, .16, 1] }
        }}
      >
        {t('404:Heading1')}
        <br/>
        {t('404:Heading2')}
      </Header>
      <TextShadow
        animate={ width >= 1024 && { opacity: .1 }}
        transition={{ duration: .6, delay: 1.6, ease: 'linear' }}
      >
        {t('404:404')}
      </TextShadow>
      <LinkWrapper
        initial={ width >= 1024 && 'hidden'}
        animate={ width >= 1024 && 'visible' }
        transition={{
          opacity: { duration: .917, delay: 1.5, ease: 'linear' },
          rotate: { duration: .917, delay: 1.5, ease: [.88, .14, .12, .86] }
        }}
        variants={{
          'visible': { opacity: 1, rotate: 0 },
          'hidden': { rotate: -8 }
        }}
      >
        <LinkPrimary path={pagesRoutes.home} text={'Buttons:Back to home page'} />
      </LinkWrapper>
    </Wrapper>
  );
};

export default NotFoundPageInfo;
