import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import useWindowSize from "@utils/useWindowSize";
import tw from "twin.macro";

import Layout from '@layouts/index';
import SEO from "@components/seo";
import NotFoundPageInfo from "@components/404/info";
import NotFoundPageImages from "@components/404/images";

const Wrapper = tw.div`md:flex`;

const NotFoundPage = () => {
  const intl = useIntl();
  const { width } = useWindowSize();

  return (
    <Layout boxHeight={790} hideFooter>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'SEO.Title.404' })}
      />
      <Wrapper>
        <NotFoundPageInfo width={ width } />
        <NotFoundPageImages width={ width } />
      </Wrapper>
    </Layout>
  );
};

export default NotFoundPage;
