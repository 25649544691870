import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

import ArrowPrimary from "@components/arrows/primary";
import DetailsRedArrow from "@components/svg/details-red-arrow";
import DetailsLarge from "@components/svg/details-large";

const Wrapper = tw(motion.div)`
  hidden
  md:block md:relative md:w-1/2
  lg:h-645 lg:opacity-0
`;

const ImageMain = tw(Img)`
  absolute!
  md:top-60 md:-right-50 md:w-full
  lg:-top-50
  xl:w-585
`;

const Arrow = styled.div`
  ${tw`
    absolute! top-280 -right-130
    lg:top-310 lg:-right-130
    xl:top-350 xl:-right-95
  `}
  
  svg {
    ${tw`w-160 transform rotate-0 xl:w-180`}
  }
`;

const RedArrow = styled.div`
  ${tw`
    absolute! top-160 -left-40 w-130 transform -scale-x-100
    lg:top-105 lg:-left-50 lg:w-180
    xl:-left-25
  `}

  g:nth-child(1), g:nth-child(2) {
    ${tw`hidden`}
  }
`;

const Dot = styled.div`
  ${tw`
    absolute! -top-90 -right-100 transform -scale-x-100 lg:-top-230 lg:-right-180
  `}
  
  svg {
    ${tw`w-220 lg:w-300`}
  }

  g:nth-child(1), g:nth-child(3), g:nth-child(5) {
    ${tw`hidden`}
  }
`;

const NotFoundPageImages = ({ width }: { width: number }) => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "404.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper
      animate={ width >= 1024 && { opacity: 1 }}
      transition={{ duration: 1.8, delay: 1.4, ease: [0.13, -0.22, 1, 1.02] }}
    >
      <ImageMain fluid={data.mainImage.childImageSharp.fluid} />
      <Arrow>
        <ArrowPrimary />
      </Arrow>
      <RedArrow>
        <DetailsRedArrow />
      </RedArrow>
      <Dot>
        <DetailsLarge />
      </Dot>
    </Wrapper>
  );
};

export default NotFoundPageImages;
